// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

.css-1d97e6z-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-1d97e6z-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.userUpdateUpload {
  display: flex;
  align-items: center;
  @media only screen and (max-width: 568px) {
    margin: 20px 0;
  }
}

.userUpdateImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}

.userUpdateIcon {
  cursor: pointer;
}

.logotib__brand__uztez {
  width: 100px;

  img {
    width: 100%;
    height: 100%;
  }
}

.special-label {
  display: none !important;
}

.input_error_message {
  margin-top: 5px;
  color: red;
}
